.select-search {
  width: 100%;
  position: relative;
  margin: 0 auto;
}

.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  border: 1px solid transparent;
  border-bottom: 1px solid #eee;
  outline: none;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: #939390;
  color: #777774;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

.select-search__options {
  list-style: none;
  padding: 0;
  margin: 0;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

.container {
  max-width: 450px;
  padding: 20px;
  margin: 0 auto 20px;
}

.mp-card {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
}

.mp-card__detail {
  color: #777774;
  font-size: 16px;
  margin-bottom: 20px;
}

.mp-card__score {
  text-align: center;
  font-size: 40px;
  margin: 20px 0 20px;
  font-family: 'Anton', sans-serif;
}

.mp-card__score__percentage {
  color: #777774;
  font-size: 28px;
  font-family: 'Lato', sans-serif;
}

.mp-card__vote-link {
  text-align: center;
}

.mp-card__vote-link a {
  text-decoration: none;
}

.mp-card__link {
  text-align: center;
  color: #0F2A62;
  border-top: 1px solid #d3d1c9;
  padding: 5px 0 0;
  margin: 0;
  font-size: 14px;
}

.mp-card__link a {
  text-decoration: none;
}

.mp-card__bar {
  margin: 10px 0;
  border-radius: 3px;
  border: 1px solid #d3d1c9;
  height: 25px;
}

.mp-card__bar__progress {
  background-color: #0F2A62;
  height: 100%;
  width: 0;
  transition: width 1.2s ease-in-out;
}

footer {
  color: #d3d1c9;
  font-size: 14px;
  text-align: center;
  padding: 10px 20px;
  margin: 50px auto 20px;
}

footer a {
  color: #d3d1c9;
}
