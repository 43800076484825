body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  color: #003B36;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html * {
  box-sizing: border-box;
}

h1, h2 {
  font-size: 24px;
  text-align: center;
  margin: 10px 0 30px;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
}

h2 {
  font-size: 22px;
  margin: 10px 0 5px;
  border-bottom: 1px solid #d3d1c9;
}

p {
  margin: 5px 0 10px;
}

#root {
  min-height: 100vh;
}

a {
  color: #0F2A62;
}
